<template>
  <div
    class="contain-select-width"
  >
    <v-select
      :items="optionItems"
      dense
      outlined
      hide-details
      :label="label"
      v-on="$listeners"
      v-bind="$attrs"
      :value="selectedItem"
    ></v-select>
  </div>
</template>

<script>
export default {
  name: "FollowersSelector",

  inheritAttrs: false,

  props: {
    label: {
      type: String,
      required: false,
      default: "Percentage"
    },

    value: {
      type: [Number, String],
      required: false,
      default: 1000
    },

    minValue: {
      type: [Number, String],
      required: false,
      default: 0
    },

    maxValue: {
      type: [Number, String],
      required: false,
      default: Infinity
    },
  },

  data: () => ({
    items: [
      {
        text: "1k",
        value: "1000",
      },
      {
        text: "3k",
        value: "3000",
      },
      {
        text: "5k",
        value: "5000",
      },
      {
        text: "10k",
        value: "10000",
      },
      {
        text: "25k",
        value: "25000",
      },
      {
        text: "50k",
        value: "50000",
      },
      {
        text: "100k",
        value: "100000",
      },
      {
        text: "250k",
        value: "250000",
      },
      {
        text: "500k",
        value: "500000",
      },
      {
        text: "1m",
        value: "1000000",
      },
      {
        text: "1m+",
        value: Infinity.toString(),
      }
    ]
  }),

  computed: {
    selectedItem() {
      return this.items.find((search) => search.value === this.value)
    },

    optionItems() {
      return this.items
        // for minimum
        .filter((item) => this.minValue === 0 ? true : Number(item.value) >= (Number(this.minValue) || 0))
        // for maximum
        .filter((item) => this.maxValue === Infinity ? true : Number(item.value) < (Number(this.maxValue) || 1_000_001))
    }
  },

  mounted() {
    this.$emit('input', this.value)
  }
}
</script>

<style lang="stylus" scoped>
.contain-select-width
  min-width 12em
  width 12em
</style>
